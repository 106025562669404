import React from "react";

import "./ProjectDescription.scss";

const ProjectDescription = (props: any) => {
    const project = props.project;

    return (
        <div className="pb-5" id={project.name}>
            <h1 className="project-name rainbow rainbow_text_animated">
                {project.sectionBaseTitle}
            </h1>

            {project.sections.map((section: any, index: number) => (
                <div
                    key={index}
                    className={"project-description project-description-one text-center pb-10 " + (index % 2 ? "reverse" : "")}
                    id={section.id}
                >
                    <div className="long-description">
                        <h3>{section.title}</h3>
                        <div className="descr">{section.description}</div>
                    </div>
                    <div className="project-img-overview">
                        <img src={section.image} alt={section.title}/>
                    </div>
                </div>
            ))}
        </div>
    );
};  

export default ProjectDescription;
