import React from "react";
import {FaHome, FaWallet, FaWpforms} from "react-icons/fa";

export const MenuItems = [
    {
        name: "Aerovek",
        link: "/",
        icon: <FaHome className="menu-item-icons" />,
        isExternalUrl: false,
    },
    {
        name: "Wallet",
        link: "/wallet",
        icon: <FaWallet className="menu-item-icons" />,
        isExternalUrl: false,
    },
    {
        name: "Apply now",
        link: "https://f87wufekgeu.typeform.com/to/hvyH6YmC?typeform-source=apeslaunchpad.io",
        icon: <FaWpforms className="menu-item-icons" />,
        isExternalUrl: true,
    }
];