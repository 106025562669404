import React from 'react';
import './TeamInformation.scss';

const TeamInformation = (props: any) => {
    const project = props.project;
    const team = project.teamMembers;

    return (
        <div className='team-description pb-5' id="Team">
            <div className='team-description-txt'>
                <h1 style={{paddingLeft: '10px'}}>Team</h1>
            </div>
            <div className='team-members'>
                {team && team.length > 0 &&
                    team.map((teamMember: any) => {
                        return (
                            <div 
                                className='team-member pt-5'
                                key={teamMember.name}
                            >
                                <img src={teamMember.profilePic} alt={teamMember.name} />
                                <div className='team-member-name'>{teamMember.name}</div>
                                <div className='team-member-nick'>{teamMember.title}</div>
                            </div>
                        )
                    })
                }
            </div>
        </div>
    );
};

export default TeamInformation;
