import * as React from 'react';
import { useState } from "react";
import {
    refreshAccount,
    transactionServices,
    useGetAccountInfo
} from "@elrondnetwork/dapp-core";
import { Button } from "react-bootstrap";
import { Container } from "react-bootstrap";
import { toast } from "react-toastify";
import NftDetailModal from "../../components/Modal/NftDetailModal";
import { swapConfigProject } from "../../config";
import { fetchAccountNFTs } from "../../helpers/apiRequests";
import './Wallet.scss';
import {buildSwapTx, buildSwapTxPayload} from "../../helpers/transactionBuilder";

const Wallet = () => {
    const { address, ledgerAccount } = useGetAccountInfo();

    const [currentToken, setCurrentToken] = useState(null);
    const [nftTokens, setNftTokens] = useState([] as any[]);
    const [showModal, setShowModal] = useState(false);
    const { sendTransactions } = transactionServices;
    const openModal = (token: any) => {
        setCurrentToken(token);
        setShowModal(true);
    }
    const /*transactionSessionId*/ [, setTransactionSessionId] = React.useState<string | null>(null);

    const hideModal = () => {
        setCurrentToken(null);
        setShowModal(false);
    }

    React.useEffect(() => {

        if (address) {

            const fetchTokens = async () => {
                const args = `?collections=${swapConfigProject.oldCollection},${swapConfigProject.newCollection}`;
                const result = await fetchAccountNFTs(address, args);
                if (200 === result.status) {
                    return result.data;
                }
                return [];
            };

            fetchTokens()
                .then((data: any) => {
                    const collectionArray = [] as any[];

                    for (const token of data) {
                        const currentCollection = token.collection;

                        const index = collectionArray
                            .findIndex(collection => collection.collection === currentCollection);

                        if (-1 === index) {
                            collectionArray.push(
                                {
                                    collection: currentCollection,
                                    tokens: [ token ]
                                }
                            );
                        } else {
                            collectionArray[index].tokens.push(token);
                        }
                    }

                    setNftTokens(collectionArray);
                })
                .catch(err => {
                    console.error(err);
                    toast.error(`Unable to fetch NFTs from Elrond API...`);
                });
        }

    }, [address]);

    // Swap an old for a new NFT
    const sendOneSwap = async (token: any) => {
        // Todo FIX : Temporary solution, it seems that payload doesn't work with ledger device
        // So we play the normal flow for non ledger account and the alternative flow for ledger account
        const swapTransaction = buildSwapTx(address, swapConfigProject.contract, [token]);

        //console.log(`isLedgerAccount: ${ledgerAccount}`);
        //if (Boolean(ledgerAccount)) {
        //    console.log(`Play scenario 1 in wallet page (brut)`);
        //    swapTransaction = buildSwapTx(address, swapConfigProject.contract, [token]);
        //} else {
        //    console.log(`Play scenario 2 in wallet page (payload)`);
        //    swapTransaction = buildSwapTxPayload(address, swapConfigProject.contract, [token]);
        //}

        await refreshAccount();

        const { sessionId /*, error*/ } = await sendTransactions({
            transactions: swapTransaction,
            transactionsDisplayInfo: {
                processingMessage: `Swap NFT`,
                errorMessage: 'Swap Failed',
                successMessage: 'Swap Success'
            },
            redirectAfterSign: false
        });

        if (sessionId != null) {
            setTransactionSessionId(sessionId);
        }
    };

    return (
        <Container>
            <h1 className="wallet-page-title">
                Launchpad NFTs
            </h1>

            {nftTokens.length > 0 && nftTokens.map((collection: any, index: number) => (
                <div key={index}>
                    <h2 className="collection-title">
                        {collection.collection}&nbsp;
                        {swapConfigProject.oldCollection === collection.collection ? "(old collection)" : ""}
                    </h2>

                    <div className="wallet-tokens-container">
                        {collection.tokens.map((token: any, subIndex: number) => (
                            <div
                                key={`${index}-${subIndex}`}
                                className="nft-card-container"
                            >
                                <img
                                    onClick={() => openModal(token)}
                                    src={token["url"]}
                                    alt={token["name"]}
                                />
                                <div className="nft-header-container">
                                    <div className="nft-title">{token.name}</div>
                                </div>

                                {swapConfigProject.oldCollection === collection.collection && (
                                    <Button
                                        variant="primary"
                                        size="lg"
                                        className="mint-btn project-mint-swap-old"
                                        onClick={() => sendOneSwap(token)}
                                    >
                                        SWAP
                                    </Button>
                                )}
                            </div>
                        ))}
                    </div>
                </div>
            ))}

            {(nftTokens.length === 0 && (
                <div className="no-nfts-message">You don&apos;t have NFTs to display...</div>
            ))}

            <NftDetailModal show={showModal} token={currentToken} hideModal={hideModal} />
        </Container>
    )
}

export default Wallet;