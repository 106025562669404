import React, { useRef, useState } from 'react';

import { AuthenticatedRoutesWrapper } from '@elrondnetwork/dapp-core';
import { useLocation } from 'react-router-dom';
import routes, { routeNames } from 'routes';
import Navbar from './Navbar';
import Sidebar from "./Sidebar";
import './Layout.scss';

const Layout = ({ children }: { children: React.ReactNode }) => {
    const { search } = useLocation();
    const childRef = useRef<any>(null);
    const [sidebarToggle, setSidebarToggle] = useState(false);

    const setSidebar = (toggle: boolean) => {
        setSidebarToggle(toggle);
        childRef?.current?.toggleSidebar(toggle);
    };

    return (
        <div className={"flex-fill wrapper " + (sidebarToggle ? "pos-fix" : "")}>
            <Sidebar ref={childRef}/>
            <main className='ape-main-cont d-flex flex-grow-1 flex-column'>
                <Navbar setSidebar={setSidebar} />
                <AuthenticatedRoutesWrapper
                routes={routes}
                unlockRoute={`${routeNames.unlock}${search}`}
                >
                    {children}
                </AuthenticatedRoutesWrapper>
            </main>
        </div>
    );
};

export default Layout;
