import React from 'react';
import {
    useState
  } from "react";
import { logout, useGetAccountInfo } from '@elrondnetwork/dapp-core';
import {
    NavItem,
    Nav,
    Navbar,
  } from "react-bootstrap";
import { FaBars } from "react-icons/fa";
import { Link } from "react-router-dom";
import "./NavigationNavbar.scss";
import MaiarLogo from "../../../assets/img/maiar_logo.png";
import MiniElrondApesLogo from "../../../assets/img/miniape.png";
import { network } from "../../../config";
import { mobileErdAddress, cropErdAddress } from "../../../helpers/stringHelper";

const NavigationNavbar = (props: any) => {

    const {setSidebar} = props;
    const { address } = useGetAccountInfo();
    const loggedIn = Boolean(address);

    const logOut = () => {
        logout(`${window.location.origin}/unlock`);
    };
    const [sidebarToggle, setSidebarToggle] = useState(false);

    const toggleSidebar = () => {
        setSidebar(!sidebarToggle);
        setSidebarToggle(!sidebarToggle);
    };

    return (
        <Navbar collapseOnSelect expand="lg" variant="dark">
            <div className="nav-fixed-top">
                <Nav className="">
                    <NavItem  className="mobile-navbar">
                        <FaBars
                            className="sidebar-toggle"
                            onClick={toggleSidebar}
                        />
                    </NavItem>
                    <Navbar.Brand className="mobile-navbar">
                        <Link className="" to={"/"}>
                            
                            <img className="minilogo" src={MiniElrondApesLogo} alt="Elrond Apes logo" />
                        </Link>
                    </Navbar.Brand>
                    <Nav.Link>
                    {loggedIn && (
                        <NavItem>
                            <button className="menu-btn claim-btn">
                                <img className="maiar-logo" src={MaiarLogo} alt="Maiar Logo" />
                                <span onClick={() => window.open(`${network.explorerAddress}accounts/${address}`, '_blank')}>
                                    <span className="crop-erd-addr">{cropErdAddress(address)}</span>
                                    <span className="mobile-erd-btn">{mobileErdAddress(address)}</span>
                                </span>
                                <span className="logout" onClick={logOut}>
                                    X
                                </span>
                            </button>
                        </NavItem>
                    )}
                    {!loggedIn && (
                        <NavItem>
                            <Link to={"/unlock"}>
                                <button className="menu-btn claim-btn">CONNECT</button>
                            </Link>
                        </NavItem>
                    )}
                    </Nav.Link>
                </Nav>
            </div>
        </Navbar>
    );
};

export default NavigationNavbar;
