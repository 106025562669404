import React, { useState } from "react";

import "./ProjectInformation.scss";
import { useGetAccountInfo } from "@elrondnetwork/dapp-core";
import { getCurrentSupply, isUserWhitelisted } from "../../helpers/apiRequests";
import CustomProgressBar from "../Layout/CustomProgressBar";
import MintDetails from "../MintDetails";
import ProjectMintSection from "../ProjectMintSection";
import ProjectSocials from "../ProjectSocials";

const ProjectInformation = (props: any) => {

    const { address } = useGetAccountInfo();

    const project = props.project;

    const [currentSupply, setCurrentSupply] = useState(0);
    const [isWhitelisted, setWhitelisted] = useState(false);

    // Get the current supply minted
    React.useEffect(() => {
        const fetchCurrentSupply = async (contract: string) => {
            const result = await getCurrentSupply(contract);
            if (200 === result.status) {
                return result.data.result;
            }
            return 0;
        }

       const projectContract = project.phases[0].contractAddress;

       if (projectContract) {
           fetchCurrentSupply(projectContract)
               .then(_currentSupply => setCurrentSupply(_currentSupply))
               .catch(err => {
                   console.error(err);
               });
       }

   }, []);


    React.useEffect(() => {
        const projectContract = project.phases[0].contractAddress;
        if (address && projectContract) {
            const fetchIsUserWhitelisted = async (contract: string) => {
                const result = await isUserWhitelisted(contract, address);
                if (200 === result.status) {
                    return result.data.result;
                }
                return false;
            }

            fetchIsUserWhitelisted(projectContract)
                .then((data) => setWhitelisted(data))
                .catch(err => {
                    console.error(err);
                    //toast.error(`Unable to fetch user whitelist flag...`);
                });
        }
    }, [address]);

    return (
         <div className="project-information">
             <div className="project-image">
                 <img src={project.mainImage} alt={`${project.name} images`} />
             </div>

             <div className="project-introduction">
                 <div className="project-title">
                     {project.name}
                 </div>

                <ProjectSocials project={project} />
                <MintDetails project={project} isWhitelisted={isWhitelisted} />
                 <div className="project-description">
                     {project.description}
                 </div>

                 <div className="project-mint">
                     <CustomProgressBar
                         data={
                             {
                                 percentage: currentSupply > 0 ? currentSupply * 100 / project.totalSupply : 0,
                                 label: `${currentSupply} / ${project.totalSupply}`
                             }
                         }
                     />

                     <ProjectMintSection project={project} />
                 </div>
             </div>
         </div>
    );
};

export default ProjectInformation;
