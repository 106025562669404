import React from 'react';
import './MintDetails.scss';

const MintDetails = (projectProps: any) => {
    const project = projectProps.project;
    const currentPhase = project.phases
        ? project.phases.find((phase: any) => phase.status === "CURRENT")
        : undefined;

    return (
        <div className='mint-details'>
            {project.totalSupply && (
                <div className='mint-details-card'>
                    SUPPLY: {project.totalSupply}
                </div>
            )}

            {currentPhase && (
                <div className='mint-details-card'>
                    PRICE: {currentPhase.price} {currentPhase.tokenName}
                </div>
            )}

            {projectProps.isWhitelisted && (
                <div className='mint-details-card whitelist-card'>
                    WHITELISTED
                </div>
            )}
        </div>
    );
};

export default MintDetails;
