import axios from "axios";
import { microserviceAddress, network } from "../config";

export const getOwnerNFTRequest = async (ownerAddress: string, nftCollection: string) => {
    return axios.get(
        `${network.apiAddress}/accounts/${ownerAddress}/nfts?collection=${nftCollection}&from=0&size=300`,
    );
};

export const getCurrentSupply = (nftContractAddress: string) => {
    return axios.get(
        `${microserviceAddress}/projects/${nftContractAddress}/current-supply`,
    );
}

export const isUserWhitelisted = (nftContractAddress: string, userAddress: string) => {
    return axios.get(
        `${microserviceAddress}/projects/${nftContractAddress}/users/${userAddress}/is-whitelisted`,
    );
}

export const fetchAccountNFTs = (address: string, args: string) => {
    return axios.get(
        `${network.apiAddress}/accounts/${address}/nfts${args}`,
    );
};