import React from 'react';
import './ProjectSocials.scss';
import {
    FaDiscord,
    FaGlobe,
    FaTelegram,
    FaTwitter
} from "react-icons/all";



const ProjectSocials = (projectProps: any) => {
    const websiteUrl = projectProps.project.websiteUrl;
    const socialLinks = projectProps.project.socials;

    return (
        <div className='project-socials'>
            <div className='project-social-icons-container'>
                {websiteUrl && (
                    <a href={websiteUrl} target="_blank" rel="noreferrer">
                        <FaGlobe className="social-icon" />
                    </a>
                )}

                {socialLinks.discord && (
                    <a href={socialLinks.discord} target="_blank" rel="noreferrer">
                        <FaDiscord className="social-icon" />
                    </a>
                )}

                {socialLinks.twitter && (
                    <a href={socialLinks.twitter} target="_blank" rel="noreferrer">
                        <FaTwitter className="social-icon" />
                    </a>
                )}

                {socialLinks.telegram && (
                    <a href={socialLinks.telegram} target="_blank" rel="noreferrer">
                        <FaTelegram className="social-icon"/>
                    </a>
                )}
            </div>
        </div>
    );
};

export default ProjectSocials;
