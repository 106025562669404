import React, {useState} from "react";
import "./ProjectMintSection.scss";
import {
    refreshAccount,
    transactionServices,
    useGetAccountInfo
} from "@elrondnetwork/dapp-core";
import { Button } from "react-bootstrap";
import Countdown from 'react-countdown';
import { toast } from "react-toastify";
import { swapConfigProject } from "../../config";
import { fetchAccountNFTs } from "../../helpers/apiRequests";
import {buildSwapTx, buildSwapTxPayload} from "../../helpers/transactionBuilder";

const ProjectMintSection = (props: any) => {

    // State
    const [ownerOldCollectionNfts, setOwnerOldCollectionNfts] = useState([] as any);

    const project = props.project;
    const now = new Date().getTime() / 1000;
    const timestamp = project.phases[0].launchTimestamp;
    const { address, ledgerAccount } = useGetAccountInfo();
    const loggedIn = Boolean(address);
    const Completionist = () => <span>MINT</span>;
    const { sendTransactions } = transactionServices;
    const /*transactionSessionId*/ [, setTransactionSessionId] = React.useState<string | null>(null);

    React.useEffect(() => {
        if (address) {
            const args = `?collections=${swapConfigProject.oldCollection}`;
            const fetchOldCollection = async () => {
                const result = await fetchAccountNFTs(address, args);
                if (200 === result.status) {
                    return result.data;
                }
                return [];
            }

            fetchOldCollection()
                .then((data: any) => setOwnerOldCollectionNfts(data))
                .catch((err: any) => {
                    console.error(err);
                    toast.error(`Unable to fetch old collection...`);
                })
        }
    }, [address]);

    const sendMint = async () => {
        if(!loggedIn || (new Date().getTime() / 1000 < timestamp)) {
            return;
        }
        const currentPhase = project.phases[0];
        const price = currentPhase.price;
        const contract = currentPhase.contractAddress;
        const mintTransaction = {
            value: String(price * 10 ** 18),
            data: 'mintNFT',
            receiver: contract
        };

        await refreshAccount();

        const { sessionId /*, error*/ } = await sendTransactions({
            transactions: mintTransaction,
            transactionsDisplayInfo: {
                processingMessage: 'Mint NFT',
                errorMessage: 'Mint Failed',
                successMessage: 'Mint Success'
            },
            redirectAfterSign: false
        });

        if (sessionId != null) {
            setTransactionSessionId(sessionId);
        }
    };

    const sendSwap = async () => {
        if (!ownerOldCollectionNfts || ownerOldCollectionNfts.length === 0) {
            return;
        }

        // Todo FIX : Temporary solution, it seems that payload doesn't work with ledger device
        // So we play the normal flow for non ledger account and the alternative flow for ledger account
        const swapTransaction = buildSwapTx(address, swapConfigProject.contract, ownerOldCollectionNfts);
        //console.log(`isLedgerAccount: ${ledgerAccount}`);
        //if (Boolean(ledgerAccount)) {
        //    console.log(`Play scenario 1 in wallet page (brut)`);
        //    swapTransaction = buildSwapTx(address, swapConfigProject.contract, ownerOldCollectionNfts);
        //} else {
        //    console.log(`Play scenario 2 in wallet page (payload)`);
        //    swapTransaction = buildSwapTxPayload(address, swapConfigProject.contract, ownerOldCollectionNfts);
        //}

        await refreshAccount();

        const { sessionId /*, error*/ } = await sendTransactions({
            transactions: swapTransaction,
            transactionsDisplayInfo: {
                processingMessage: `Swap ${ownerOldCollectionNfts.length === 1 ? "NFT" : "NFTs"}`,
                errorMessage: 'Swap Failed',
                successMessage: 'Swap Success'
            },
            redirectAfterSign: false
        });

        if (sessionId != null) {
            setTransactionSessionId(sessionId);
        }
    };

    const rendererFnc = ({ days, hours, minutes, seconds, completed }: any) => {
        if (completed) {
          // Render a completed state
          return <Completionist />;
        } else {
          // Render a countdown
          return <span>{days > 0 ? days + 'd' : ''} {hours > 0 ? hours + 'h' : ''} {minutes > 0 ? minutes + 'm' : ''} {seconds}s</span>
        }
      };
      

  return (
    <div className="project-mint-btn">
      <Button 
        variant="primary"
        size="lg"
        className="mint-btn"
        disabled={true}
        onClick={() => sendMint()}
      >

        {now < timestamp
            ? <Countdown
                date={new Date(timestamp*1000)}
                renderer={rendererFnc}
            ><Completionist />
            </Countdown>
            : (loggedIn ? "SOLD OUT" : "CONNECT WALLET")}
      </Button>

      {ownerOldCollectionNfts && ownerOldCollectionNfts.length > 0 && (
          <Button
              variant="primary"
              size="lg"
              className="swap-btn"
              onClick={() => sendSwap()}
          >
            SWAP
          </Button>
      )}
    </div>
  );
};

export default ProjectMintSection;
