import "./CustomProgressBar.scss";
import React from "react";
import { Container, ProgressBar } from "react-bootstrap";

const CustomProgressBar = (props: any) => {
    const barData = props.data;
    return (
        <Container 
            fluid
            className="cstm-progress-bar"
        >
            {barData.label && (
                <Container fluid className="text-center custom-progress-bar-label">
                    {barData.label}
                </Container>
            )}
            <ProgressBar
                striped
                variant="success"
                now={barData.percentage}
            />
        </Container>
    );
};

export default CustomProgressBar;