import { dAppName } from 'config';
import withPageTitle from './components/PageTitle';
import ProjectMint from './pages/ProjectMint';
import Wallet from "./pages/Wallet";

export const routeNames = {
  home: '/',
  wallet: '/wallet',
  unlock: '/unlock',
  ledger: '/ledger',
  walletconnect: '/walletconnect'
};

//TODO Make the title dynamic
const routes: Array<any> = [
  {
    path: routeNames.home,
    title: 'Aerovek',
    component: ProjectMint
  },
  {
    path: routeNames.wallet,
    title: "Wallet",
    component: Wallet,
    authenticatedRoute: true
  }
];

const mappedRoutes = routes.map((route) => {
  const title = route.title
    ? `${route.title} • Elrond ${dAppName}`
    : `Elrond ${dAppName}`;

  const requiresAuth = Boolean(route.authenticatedRoute);
  const wrappedComponent = withPageTitle(title, route.component);

  return {
    path: route.path,
    component: wrappedComponent,
    authenticatedRoute: requiresAuth
  };
});

export default mappedRoutes;
