import { Buffer } from "buffer";
import {
  Address,
  AddressValue,
  BytesValue,
  ContractFunction,
  TokenIdentifierValue,
  TransactionPayload, TypedValue,
  U32Value
} from "@elrondnetwork/erdjs/out";


export const buildSwapTx = (senderAddress: string, scAddress: string, nftList: any[]) => {
  console.log(nftList);
  const args = [];
  if (nftList.length === 1) {
    const tokenIdentifier = nftList[0].collection;
    const nonce = nftList[0].nonce;

    args.push("ESDTNFTTransfer");
    args.push(`${strToHex(tokenIdentifier)}`);
    args.push(`${toHex(nonce)}`);
    args.push(`${toHex(1)}`);
    args.push(`${new Address(scAddress).hex()}`);
    args.push(`${strToHex("swap")}`);

  } else if (nftList.length > 1) {
    args.push("MultiESDTNFTTransfer");
    args.push(`${new Address(scAddress).hex()}`);
    args.push(`${toHex(nftList.length)}`);
    for (let i = 0; i < nftList.length; i++) {
      args.push(`${strToHex(nftList[i].collection)}`);
      args.push(`${toHex(nftList[i].nonce)}`);
      args.push(`${toHex(1)}`);
    }
    args.push(`${strToHex("swap")}`);
  }
  console.log(args.join("@"))
  return {
    receiver: senderAddress,
    value: "0",
    data: args.join("@")
  };
};

const strToHex = (str: string) => {
  return Buffer.from(new TextEncoder().encode(str)).toString("hex");
}

// Convert an nb to an hex.
const toHex = (nb: number) => {
  const hexStringNumber = nb.toString(16);
  return hexStringNumber.length %2 === 0 ? hexStringNumber : `0${hexStringNumber}`;
};

export const buildSwapTxPayload = (senderAddress: string, scAddress: string, nftList: any[]) => {
  let payload = null;
  console.log(senderAddress);
  console.log(scAddress);
  console.log(nftList);

  if (nftList.length === 1) {
    const tokenIdentifier = nftList[0].collection;
    const nonce = nftList[0].nonce;
    payload = TransactionPayload.contractCall()
        .setFunction(new ContractFunction("ESDTNFTTransfer"))
        .setArgs([
          new TokenIdentifierValue(Buffer.from(new TextEncoder().encode(tokenIdentifier))),
          new U32Value(nonce),
          new U32Value(1),
          new AddressValue(new Address(scAddress)),
          new BytesValue(Buffer.from(new TextEncoder().encode("swap")))
        ])
        .build();
  } else if (nftList.length > 1) {
    const args = [] as TypedValue[];
    args.push(new AddressValue(new Address(scAddress)));
    args.push(new U32Value(nftList.length));

    for (const token of nftList) {
      args.push(new TokenIdentifierValue(Buffer.from(new TextEncoder().encode(token.collection))));
      args.push(new U32Value(token.nonce));
      args.push(new U32Value(1));
    }

    args.push(new BytesValue(Buffer.from(new TextEncoder().encode("swap"))));

    payload = TransactionPayload.contractCall()
        .setFunction(new ContractFunction("MultiESDTNFTTransfer"))
        .setArgs(args)
        .build();
  }

  console.log(payload);
  console.log({
    receiver: senderAddress,
    value: "0",
    data: payload
  });

  return {
    receiver: senderAddress,
    value: "0",
    data: payload
  };
};