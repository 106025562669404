import React from 'react';

import './ProjectMint.scss';
import ProjectDescription from '../../components/ProjectDescription';
import ProjectInformation from '../../components/ProjectInformation';
import TeamInformation from '../../components/TeamInformation';
import {currentProject} from "../../config";

const ProjectMint = () => {

    const project = currentProject;
    const switches = [ project.name, 'Aviators Club', 'Team' ];

    return (
        <div className='project-mint d-flex flex-fill container'>
            <div className='row w-100'>
                <div className='home-container'>
                    <ProjectInformation project={project} />
                </div>
                <hr />
                <div className="project-sections">
                    <div className='project-switch pb-5'>
                        {switches.map((section, index) => (
                            <a
                                key={section}
                                className=''
                                href={`#${section}`}
                            >
                                <div className='title'>
                                    {switches[index]}
                                </div>
                            </a>))}
                    </div>

                    <ProjectDescription project={project} id={project.name} />
                    <TeamInformation project={project} id="Team" />
                </div>
            </div>
        </div>
    );
};

export default ProjectMint;
