import React, {useLayoutEffect} from "react";
import { 
    forwardRef, 
    useState, 
    useImperativeHandle
} from "react";
import {
  Nav,
  Navbar,
} from "react-bootstrap";
import { Link} from "react-router-dom";
import ElrondApesLogo from "../../../assets/img/logo.png";
import MiniElrondApesLogo from "../../../assets/img/miniape.png";
import "./Sidebar.scss";
import { MenuItems } from "./MenuItems";

const Sidebar = forwardRef((props, ref) => {
    const [toggleBar, setToggleBar] = useState(false);

    const useWindowSize = () => {
        const [size, setSize] = useState([0, 0]);
        useLayoutEffect(() => {
            function updateSize() {
                setSize([window.innerWidth, window.innerHeight]);
            }
            window.addEventListener('resize', updateSize);
            updateSize();
            return () => window.removeEventListener('resize', updateSize);
        }, []);
        return size;
    };

    const [width,] = useWindowSize();

    useImperativeHandle(ref, () => ({
        toggleSidebar(toggle: boolean) {
            setToggleBar(toggle);
        }
      }));

    return (
        <>
            <Nav
                className={toggleBar ? "sidebar sidebar-show" : "sidebar sidebar-hide"}
                activeKey="/home"
            >
                <Navbar.Brand className="sidebar-mobile-logo">
                    <Link className="d-flex navbar-brand mr-0" to={"/"}>
                        <img className="logo" src={ElrondApesLogo} alt="Elrond Apes logo" width={215} />
                        <img className="minilogo" src={MiniElrondApesLogo} alt="Elrond Apes logo" />
                    </Link>
                </Navbar.Brand>
                {MenuItems.map((item, i) => {
                    return (
                        <Nav.Item key={i} className="menu-item">
                            <Nav.Link href={item.link} className="sidebar_link">
                                {item.icon}
                                <span
                                    hidden={width < 1200 && !toggleBar}
                                    className="menu-item-name"
                                >
                                    {item.name}
                                </span>
                            </Nav.Link>
                        </Nav.Item>
                    );
                })}
            </Nav>
        </>
    );
});

export default Sidebar;
