import React from "react";
import { Container, Modal } from "react-bootstrap";
import './NftDetailModal.scss';

const NftDetailModal = (props: any) => {
    const show = props.show as boolean;
    const token = props.token;
    return (
        <Modal
            show={show}
            onHide={props.hideModal}
            size="xl"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            {token && (
                <Modal.Body className="nft-modal-body">
                    <Container className="nft-modal-container">
                        <div className="nft-image">
                            <img src={token.url} alt={token.name} />
                        </div>
                        <div className="nft-global">
                            <div className="nft-name">
                                {token.name}
                            </div>

                            <div className="nft-identifier">
                                {token.identifier}
                            </div>

                            <div className="nft-description">
                                {token.metadata ? token.metadata.description : "N/A"}
                            </div>

                            {token.metadata && token.metadata.attributes && (
                                <Container className="attribute-list">
                                    {token.metadata.attributes.map((attribute: any, index: number) => (
                                        <div
                                            key={index}
                                            className="attribute-block">
                                            <div className="attribute-type">
                                                {attribute["trait_type"]}
                                            </div>
                                            <div className="attribute-value">
                                                {attribute.value}
                                            </div>
                                        </div>
                                    ))}
                                </Container>
                            )}
                        </div>
                    </Container>
                </Modal.Body>
            )}
        </Modal>
    );
};

export default NftDetailModal;