export const cropErdAddress = (address: string) => {
  if (address) {
    if (address.length > 20) {
      const start = address.substring(0, 8);
      const end = address.substring(address.length - 4, address.length);
      return `${start}...${end}`;
    }
    return address;
  }
  return "";
};

export const mobileErdAddress = (address: string) => {
    if (address) {
      if (address.length > 20) {
        const start = address.substring(0, 4);
        const end = address.substring(address.length - 4, address.length);
        return `${start}...${end}`;
      }
      return address;
    }
    return "";
  };

  
export const cropTokenIdentifier = (tokenIdentifier: string) => {
  return tokenIdentifier.includes("-")
    ? tokenIdentifier.substring(0, tokenIdentifier.lastIndexOf("-"))
    : tokenIdentifier;
};